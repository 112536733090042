import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/components/HeroSection';
import HERO_IMG from '../../images/product-assets/finance-page-hero-img.svg';
import Vendors from '../../components/reusable/vendors/Index';
import AuthenticationSteps from '../../sections/Industries/financial-institutions/AuthenticationSteps';
import IdentityFrauds from '../../sections/Industries/financial-institutions/IdentityFrauds';
import FraudPrevention from '../../sections/Industries/financial-institutions/FraudPrevention';
import Resources from '../../components/reusable/Resources';
import {
    FINANCE_PAGE_FAQS,
    FINANCE_PAGE_RESOURCES,
    ID_FRAUD_PREVENTIONS,
    ID_FRAUDS,
} from '../../sections/Industries/utilities';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BottomBanner from '../../components/reusable/BottomBanner';
import MetaTagsProvider from '../../components/MetaTagsProvider';
import { SAFETY_SECURITY_BANNER_CONTENTS } from '../../helper/constant';

const Finance = () => {
    return (
        <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=finance&utm_campaign=navbar">
            <MetaTagsProvider
                title="Authenticate: Instant Onboarding, KYC & AML Solutions"
                description="Authenticate empowers financial institutions with instant onboarding, global identity verification, and advanced AML screening. Prevent fraud and ensure compliance seamlessly."
                keywords="Authenticate, instant onboarding, KYC compliance, AML compliance, identity verification, financial institutions, fraud prevention, global KYC, AML screening, customer onboarding, financial compliance, secure transactions, regulatory compliance"
                path="industries/finance"
                ogTitle="Authenticate: Instant Onboarding, KYC & AML Solutions"
                ogDescription="Experience secure instant onboarding with Authenticate's global ID verification and advanced AML screening. Prevent fraud and ensure compliance effortlessly."
                twitterDescription="Streamline onboarding with Authenticate's global ID verification and AML screening. Secure your financial institution against fraud and ensure compliance."
                ogImage="https://cdn.authenticating.com/public/website/product_pages/OG-financial-page.png"
            />
            <HeroSection
                category="Industries"
                pageTitle="Finance"
                heroImage={HERO_IMG}
                heading="Onboarding Made Safe for Financial Institutions"
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=finance&utm_campaign=hero"
                subHeading="Empowered by global identity verification and advanced AML screening, financial institutions rely on Authenticate for seamless customer onboarding and instant KYB, securing every financial transaction with unmatched precision."
            />
            <Vendors />
            <AuthenticationSteps />
            <IdentityFrauds
                heading="Identity Fraud Costs Financial Institutions $6 Billion"
                whitepaperPath="/whitepapers/finance"
                dataOne={ID_FRAUDS.DATA_1}
                dataTwo={ID_FRAUDS.DATA_2}
            />
            <FraudPrevention
                cardOne={ID_FRAUD_PREVENTIONS.DATA_1}
                cardTwo={ID_FRAUD_PREVENTIONS.DATA_2}
            />
            <Resources item={FINANCE_PAGE_RESOURCES} />
            <ProductFaq
                faqData={FINANCE_PAGE_FAQS}
                subHeading="Everything You Need to Know About Fraud Protection in Financial Institutions"
                animation
                titleColor="#262626"
            />
            <BottomBanner
                data={SAFETY_SECURITY_BANNER_CONTENTS}
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=finance&utm_campaign=footer"
            />
        </RootLayout>
    );
};

export default Finance;
